import React, { Component } from 'react'
import { Swipeable } from 'react-swipeable'
import { DgPicture } from 'dg-utils'
import { Spring } from 'react-spring/renderprops'

// Styles
import './testimonial_google.scss'

// Assets
import arrow_left from '/../assets/arrow_left.svg'
import arrow_right from '/../assets/arrow_right.svg'
import googleLogo from '/../assets/google.svg'

const star_full = (
  <svg
    aria-hidden='true'
    focusable='false'
    data-prefix='fas'
    data-icon='star'
    className='star'
    role='img'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 576 512'
  >
    <path
      fill='currentColor'
      className='fa-primary'
      d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
    ></path>
  </svg>
)

const star_half = (
  <svg
    aria-hidden='true'
    focusable='false'
    data-prefix='fas'
    data-icon='star-half-alt'
    className='star'
    role='img'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 536 512'
  >
    <path
      fill='currentColor'
      className='fa-primary'
      d='M508.55 171.51L362.18 150.2 296.77 17.81C290.89 5.98 279.42 0 267.95 0c-11.4 0-22.79 5.9-28.69 17.81l-65.43 132.38-146.38 21.29c-26.25 3.8-36.77 36.09-17.74 54.59l105.89 103-25.06 145.48C86.98 495.33 103.57 512 122.15 512c4.93 0 10-1.17 14.87-3.75l130.95-68.68 130.94 68.7c4.86 2.55 9.92 3.71 14.83 3.71 18.6 0 35.22-16.61 31.66-37.4l-25.03-145.49 105.91-102.98c19.04-18.5 8.52-50.8-17.73-54.6zm-121.74 123.2l-18.12 17.62 4.28 24.88 19.52 113.45-102.13-53.59-22.38-11.74.03-317.19 51.03 103.29 11.18 22.63 25.01 3.64 114.23 16.63-82.65 80.38z'
    ></path>
  </svg>
)

const star_empty = (
  <svg
    aria-hidden='true'
    focusable='false'
    data-prefix='far'
    data-icon='star'
    className='star'
    role='img'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 576 512'
  >
    <path
      fill='currentColor'
      className='fa-primary'
      d='M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z'
    ></path>
  </svg>
)

class TestimonialGoogle extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentIndex: 0
    }

    this.setIndex = this.setIndex.bind(this)
  }

  setIndex(index) {
    if (index < 0) {
      index = this.props.testimonials.length - 1
    }

    if (index >= this.props.testimonials.length) {
      index = 0
    }

    this.setState({ currentIndex: index })
  }

  getStars(rating) {
    //need to convert string to number
    let newRating

    switch (rating) {
      case 'FIVE':
        newRating = 5
        break
      case 'FOUR':
        newRating = 4
        break
      case 'THREE':
        newRating = 3
        break
      case 'TWO':
        newRating = 2
        break
      case 'ONE':
        newRating = 1
        break
      case 'ZERO':
        newRating = 0
        break
      default:
        newRating = 5
    }

    rating = this.roundHalf(newRating)
    let ratingRender = []
    for (let index = 1; index <= (this.props.maxRating || 5); index++) {
      if (index <= newRating) {
        // Loop til end
        ratingRender.push(star_full)
      } else if (newRating > index - 1) {
        // Check for halfs
        ratingRender.push(star_half)
      } else {
        ratingRender.push(star_empty) // Otherwise show empty
      }
    }

    return ratingRender
  }

  roundHalf(num) {
    return Math.round(num * 2) / 2
  }

  render() {
    let indictors = this.props.testimonials.map((testimonial, index) => (
      <button
        key={`indicator_no_${index}`}
        type='button'
        className={
          this.state.currentIndex === index
            ? 'testimonial-indicator active'
            : 'testimonial-indicator'
        }
        onClick={() => this.setIndex(index)}
      ></button>
    ))

    const config = {
      onSwipedLeft: () => this.setIndex(this.state.currentIndex - 1),
      onSwipedRight: () => this.setIndex(this.state.currentIndex + 1),
      preventDefaultTouchmoveEvent: true,
      trackMouse: true
    }

    return (
      <Swipeable {...config}>
        <div
          className={
            this.props.containerClass
              ? `testimonial_google-container ${this.props.containerClass}`
              : 'testimonial_google-container'
          }
        >
          <div className='left'>
            <div className='content'>
              <div className='previous-container'>
                <button
                  type='button'
                  className=''
                  onClick={() => this.setIndex(this.state.currentIndex - 1)}
                >
                  <DgPicture>
                    <img
                      src={
                        this.props.arrowLeft ? this.props.arrowLeft : arrow_left
                      }
                      alt='previous'
                    />
                  </DgPicture>
                </button>
              </div>
              <div className='card'>
                <div className='header'>
                  {!this.props.profileImageHide && (
                    <div className='img'>
                      <DgPicture>
                        <img
                          src={
                            this.props.testimonials[this.state.currentIndex]
                              .reviewer.profilePhotoUrl
                          }
                          alt={`${
                            this.props.testimonials[this.state.currentIndex]
                              .reviewer.displayName
                          }`}
                        />
                      </DgPicture>
                    </div>
                  )}
                  <div className='name'>
                    <h3>
                      {
                        this.props.testimonials[this.state.currentIndex]
                          .reviewer.displayName
                      }
                    </h3>
                  </div>
                  {!this.props.hideStars && (
                    <div className='star-rating'>
                      this.getStars(
                      this.props.testimonials[this.state.currentIndex]
                      .starRating )
                    </div>
                  )}
                </div>
                <div className='body'>
                  <p>
                    {this.props.testimonials[this.state.currentIndex].comment}
                  </p>
                </div>
                <div className='date'>
                  <p>
                    {new Date(
                      this.props.testimonials[
                        this.state.currentIndex
                      ].createTime
                    ).toLocaleDateString()}
                  </p>
                </div>
              </div>
              <div className='outline'></div>
              <div className='rectangle'></div>
              <div className='next-container'>
                <button
                  type='button'
                  className=''
                  onClick={() => this.setIndex(this.state.currentIndex + 1)}
                >
                  <DgPicture>
                    <img
                      src={
                        this.props.arrowRight
                          ? this.props.arrowRight
                          : arrow_right
                      }
                      alt='next'
                    />
                  </DgPicture>
                </button>
              </div>
            </div>
          </div>
          <div className='right'>
            <div className='content'>
              {this.props.header && this.props.header}
              {this.props.headerInfo && this.props.headerInfo}
              {this.props.overallRating && (
                <div className='google-card'>
                  <div className='img'>
                    <DgPicture>
                      <img src={googleLogo} alt='google logo' />
                    </DgPicture>
                  </div>
                  <div className='rating'>
                    <Spring
                      from={{ number: 0 }}
                      to={{ number: this.props.overallRating }}
                    >
                      {(props) =>
                        this.props.overallRating && (
                          <p>{`${props.number.toFixed(1)} Google Rating`}</p>
                        )
                      }
                    </Spring>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Swipeable>
    )
  }
}

export default TestimonialGoogle
