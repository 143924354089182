import React from 'react'
// import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form'
import { input } from '../../fields/input'
import { required } from '../../validation/validation'

import './customForm.scss'

let Custom_form = (props) => {
  const {
    formClass,
    formHeader,
    innerContainerClass,
    headerContainerClass,
    buttonContainerClass,
    fields,
    submitText,
    submitClass,
    handleSubmit,
    pristine,
    submitting,
    onSubmit
  } = props

  return (
    <form
      className={formClass ? formClass : ''}
      onSubmit={handleSubmit(onSubmit)}
    >
      {formHeader ? <div className={headerContainerClass ? `${headerContainerClass}` : 'header-container'}>{formHeader}</div> : ''}

      {fields.map((field, index, array) => {
        return <div className={innerContainerClass ? `${innerContainerClass}` : 'fields-container'}key={`${index}`}>{field}</div>
      })}
      <div className={buttonContainerClass ? `${buttonContainerClass}` : 'field-group'}>
        <button type='submit' className={submitClass ? `${submitClass}` : ''} disabled={submitting}>
            {submitText ? `${submitText}` : 'Submit'}
        </button>
      </div>
    </form>
  )
}

Custom_form = reduxForm({
  form: 'Custom_form'
})(Custom_form)

// Custom_form = connect(null,{ })(Login_form);

export const CustomForm = Custom_form
