import React, { Component } from 'react';
// Actions

// Components

// Assets
const sourceTypes = [
    {
        type: `image/webp`,
        extension: 'webp'
    },
    {
        type: `image/png`,
        extension: 'png'
    },
    {
        type: `image/jpeg`,
        extension: 'jpg'
    },
    {
        type: `image/jpeg`,
        extension: 'jpeg'
    }
];


class DgPicture extends Component {

    constructor(props) {
        super(props);

        this.getSources = this.getSources.bind(this);
    }

    getSources(src, sources, path) {
        let tmp = [];


        sourceTypes.forEach((value => {
            if(value.extension === 'webp') {
                // let source = src.split('.').slice(0,-1).join('.');
                /* require(`${path}.${value.extension}`)
                .then(uri => {
                    tmp.push(<source type={value.type} srcSet={uri} />);
                });
                */
                /*
                const uri = require.context(`../../assets/Web_Assets/help_contact_feature.webp`, true);
                tmp.push(<source type={value.type} srcSet={uri} />);
                */
               /*
               import('../assets/Web_Assets/help_contact_feature.webp')
               .then(uri => {
                    tmp.push(<source type={value.type} srcSet={uri} />);
               })
               */
                // tmp.push(<source type={value.type} srcSet={img} />);
            }
        }));

        
/*
        sources.forEach((value) => {
            let extension = value.split('.').pop();
            let source = sourceTypes.find(a => a.extension === extension);

            if(source) {
                tmp.push(<source type={source.type} srcSet={value} />)
            }
        });
*/
        return tmp;
    }

    render() {

        return (
            <picture className={this.props.className ? this.props.className : ""}>
                {this.props.children}
            </picture>
        );
    }
}

export default DgPicture;
