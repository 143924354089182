import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { dgInput, dgSelect, dgUpload, dgTextArea, dgInputSelect, required } from 'dg-forms';
import WYSIWUG_Editor from '../wysiwug/WYSIWUG_Editor';
import './blogEditor.scss';
import "dg-forms/dist/index.css";

let Blog_Form = props => {
  const { 
    handleSubmit,
    submitting, 
    clearForm, 
    post,
    header_image,
    tags
  } = props;

  const renderTags = [];
  if(
    tags &&
    tags.length > 0
  ) {
    renderTags = tags.map((value, index) => {
      <option 
        key={`redux_blogs_tags_${index}`}
        value={value}
      >
        {value}
      </option>
    })
  }
  
  return (
    <form onSubmit={handleSubmit} className="BlogForm">
      <div className="container-row">
        <Field
          name="title"
          type="text"
          component={dgInput}
          containerClass="field-group title"
          placeholder="My New Post"
          label="Post Title"
          labelClass="required"
          validate={[required]}
        />
        <Field
          labelClass="required"
          label="Status"                    
          name="status"
          containerClass="field-group status"
          component={dgSelect}
          validate={[required]}
          defaultValue={"Draft"}
        >
          <option value="">Please Select</option>
          <option value="Draft">Draft</option>
          <option value="Published">Published</option>
        </Field>
        
      </div>
      <div className="container-row">
        {
          header_image ? (
            <div className="image-preview">
              <img
                className="img"
                src={header_image}
                alt="header img preview"
              />
            </div>
          ) : ''
        }
        <Field
          label="Article Image"
          labelClass=""
          multiple={false}
          accept=".png, .jpg, .jpeg"
          name="header_image"
          component={dgUpload}
          validate={[]}
        />
        <Field
          name="tag"
          type="text"
          component={dgInputSelect}
          containerClass="field-group tag"
          placeholder="Post Tag"
          label="Post Tag (default to General)"
          labelClass="required"
          validate={[required]}
        >
          <option value="" selected>Please Select</option>
          <option value="General">General</option>
          {renderTags}
        </Field>
      </div>
      <div className="container-row">
        <Field
          name="summary"
          component={dgTextArea}
          label="Article Summary"
          labelClass="required"
          validate={[required]}
      />
      </div>
      <div className="container-row">
        <div className="form-group">
          <button 
            type="submit" 
            className="redux-blogs-btn-primary" 
            disabled={submitting}
          >
            Save
          </button>
        </div>
      </div>
      <div>
        <Field
          name='wysiwyg'
          component={WYSIWUG_Editor}
          clearForm={clearForm}
          post={post}
        />
      </div>
    </form>
  )
}

Blog_Form = reduxForm({
  form: 'Blog_Form'
})(Blog_Form);

export const BlogForm = Blog_Form
