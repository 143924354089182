import React, { Component } from 'react';

// Styles
import "../pagination.scss";

// Actions

// Components

class Pagination extends Component {

    render() {

        return (
            <div className="pagination">
                <button 
                    type="button" 
                    className="btn-link prev" 
                    onClick={() => this.props.paginate(0, this.props.page_size)}
                    disabled={this.props.page <= 0}
                >
                    <svg 
                        aria-hidden="true" 
                        focusable="false" 
                        data-prefix="fas" 
                        data-icon="angle-double-left" 
                        class="svg-inline--fa fa-angle-double-left fa-w-14" 
                        role="img" 
                        xmlns="http://www.w3.org/2000/svg" 
                        viewBox="0 0 448 512"
                    >
                        <path 
                            fill="currentColor" 
                            d="M223.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L319.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L393.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34zm-192 34l136 136c9.4 9.4 24.6 9.4 33.9 0l22.6-22.6c9.4-9.4 9.4-24.6 0-33.9L127.9 256l96.4-96.4c9.4-9.4 9.4-24.6 0-33.9L201.7 103c-9.4-9.4-24.6-9.4-33.9 0l-136 136c-9.5 9.4-9.5 24.6-.1 34z"
                            className="fa-primary"
                        >
                        </path>
                    </svg>
                </button>
                <button 
                    type="button" 
                    className="btn-link prev" 
                    onClick={() => this.props.paginate(this.props.page - 1, this.props.page_size)}
                    disabled={this.props.page <= 0}
                >
                    <svg 
                        aria-hidden="true" 
                        focusable="false" 
                        data-prefix="fas" 
                        data-icon="angle-left" 
                        class="svg-inline--fa fa-angle-left fa-w-8" 
                        role="img" 
                        xmlns="http://www.w3.org/2000/svg" 
                        viewBox="0 0 256 512"
                    >
                        <path 
                            fill="currentColor" 
                            d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"
                            className="fa-primary"
                        >
                        </path>
                    </svg>
                </button>
                <div className="currentPage">
                    {this.props.page + 1} of {this.props.total_pages}
                </div>
                <button 
                    type="button" 
                    className="btn-link next" 
                    onClick={() => this.props.paginate(this.props.page + 1, this.props.page_size)}
                    disabled={this.props.page >= (this.props.total_pages - 1)}
                >
                    <svg 
                        aria-hidden="true" 
                        focusable="false" 
                        data-prefix="fas" 
                        data-icon="angle-right" 
                        class="svg-inline--fa fa-angle-right fa-w-8" 
                        role="img" 
                        xmlns="http://www.w3.org/2000/svg" 
                        viewBox="0 0 256 512"
                    >
                        <path 
                            fill="currentColor" 
                            d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"
                            className="fa-primary"
                        >
                        </path>
                    </svg>
                </button>
                <button 
                    type="button" 
                    className="btn-link next"
                    onClick={() => this.props.paginate((this.props.total_pages - 1), this.props.page_size)}
                    disabled={this.props.page >= (this.props.total_pages - 1)}
                >
                    <svg 
                        aria-hidden="true" 
                        focusable="false" 
                        data-prefix="fas" 
                        data-icon="angle-double-right" 
                        class="svg-inline--fa fa-angle-double-right fa-w-14" 
                        role="img" 
                        xmlns="http://www.w3.org/2000/svg" 
                        viewBox="0 0 448 512"
                    >
                        <path 
                            fill="currentColor" 
                            d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34zm192-34l-136-136c-9.4-9.4-24.6-9.4-33.9 0l-22.6 22.6c-9.4 9.4-9.4 24.6 0 33.9l96.4 96.4-96.4 96.4c-9.4 9.4-9.4 24.6 0 33.9l22.6 22.6c9.4 9.4 24.6 9.4 33.9 0l136-136c9.4-9.2 9.4-24.4 0-33.8z"
                            className="fa-primary"
                        >
                        </path>
                    </svg>
                </button>
            </div>
        );
    }
}

export default Pagination;
