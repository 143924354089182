import React, { useState, useRef, useEffect, Suspense } from 'react'

/**
 * This method is derived from https://dev.to/anxinyang/easy-lazy-loading-with-react-intersection-observer-api-1dll
 * Certain elements of the original code in the example do not function correctly, and hence have been adapted here
 * Primarily this refers to typesetting which was implemented in the origial code
 *
 * */

// Function to determine if a component is visible or not on the screen
export function useIntersectionObserver(ref, options, forward = true) {
  const [element, setElement] = useState(null)
  const [isIntersecting, setIsIntersecting] = useState(false)
  const observer = useRef(null)

  const cleanOb = () => {
    if (observer.current) {
      observer.current.disconnect()
    }
  }

  useEffect(() => {
    setElement(ref.current)
  }, [ref])

  useEffect(() => {
    if (!element) return
    cleanOb()
    const ob = (observer.current = new IntersectionObserver(
      ([entry]) => {
        const isElementIntersecting = entry.isIntersecting
        if (!forward) {
          setIsIntersecting(isElementIntersecting)
        } else if (forward && !isIntersecting && isElementIntersecting) {
          setIsIntersecting(isElementIntersecting)
          cleanOb()
        }
      },
      { ...options }
    ))
    ob.observe(element)
    return () => {
      cleanOb()
    }
  }, [element, options])

  return isIntersecting
}

/*
interface LazyLoadProps {
        tag?: ComponentType | keyof JSX.IntrinsicElements
        children: ReactNode
        style?: CSSProperties
        className?: string
        root?: Element | Document | null
        threshold?: number | number[]
        rootMargin?: string
        forward?: boolean
}
    */

// Wrapper component to allow lazy loading of any component
// The props for this function can be seen in the commented out interface called LazyLoadProps
export function LazyLoad(props) {
  const { tag = 'div', children, style, className } = props
  const Tag: any = tag
  const ref = useRef(null)
  const isIntersecting = useIntersectionObserver(
    ref,
    {
      root: props.root ?? null,
      threshold: props.threshold ?? 0,
      rootMargin: props.rootMargin
    },
    props.forward
  )

  if (isIntersecting) {
    return (
      <Tag
        style={style}
        className={className}
        children={isIntersecting ? children : null}
      />
    )
  } else {
    return (
      <div ref={ref} style={{ height: '100vh', width:'100%' }}>
        .
      </div>
    )
  }
}

// Wrapper component to allow lazy loading of any component
// The props for this function can be seen in the commented out interface called LazyLoadProps
export function LazyComponent(props) {
  const { fallback = null, Component, ...componentProps } = props
  const ref = useRef(null)
  const isIntersecting = useIntersectionObserver(
    ref,
    {
      root: props.root ?? null,
      threshold: props.threshold ?? 0,
      rootMargin: props.rootMargin
    },
    props.forward
  )

  if (isIntersecting) {
    return (
      <Suspense fallback={fallback}>
        <Component {...componentProps} />
      </Suspense>
    )
  } else {
    return (
      <div ref={ref} style={{ height: '100vh', width:'100%' }}>
        .
      </div>
    )
  }
}
