import React, { Component } from 'react'
import './card.scss'
// Assets
import news_1 from '../assets/news-1.jpg'

class CardAltSmall extends Component {
  render() {
    return (
      <div
        style={this.props.style}
        className={
          this.props.cardClass
            ? `blog-card ${this.props.cardClass}`
            : 'blog-card'
        }
        onClick={() => this.props.onSelect(this.props.post)}
      >
        <div className='img'>
          <img
            src={
              this.props.post &&
              this.props.post.header_image &&
              this.props.post.header_image !== ''
                ? this.props.post.header_image
                : news_1
            }
            alt='post header img'
          />
          <h4>{this.props.post.title}</h4>
          {this.props.imageTint ? (
            <div className={this.props.imageTint}></div>
          ) : (
            ''
          )}
        </div>
        <div className='blog-card-body'>
          <h5>{this.props.post.summary}</h5>
          {this.props.linkText ? this.props.linkText : ''}
        </div>
      </div>
    )
  }
}

export default CardAltSmall
