import React, { Component } from 'react';
import './blogEditor.scss';

// Actions
// import { createNewPost, setClearPostForm } from '../../../actions/post.action';
import { getAllTags } from '../../actions/post.actions';

// Components
import { BlogForm } from './blogForm.component';

class EditPostPage extends Component {

    componentDidMount() {
        getAllTags();
    }
    
    render() {
        return (
            <div className='blogEditor'>
                {
                    this.props.post &&
                    this.props.post.title != '' ? (
                        <BlogForm 
                            onSubmit={this.props.onSubmit}
                            initialValues={this.props.post}
                            clearForm={true}
                            post={this.props.post}
                            header_image={this.props.post?.header_image}
                            tags={this.props.post?.tags}
                        />
                    ) : (
                        this.props.noPostComponent ? this.props.noPostComponent : (
                            <h1>No Post Selected</h1>
                        )
                    )
                }
            </div>
        );
    }
}

export default EditPostPage;
