import React, { Component } from 'react';

// Styles

// Actions

// Components
import { default as Standard } from './standard/standard.component';

class Pagination extends Component {

    render() {

        switch (this.props.type) {
            case 'Standard':
                return (
                    <Standard 
                        paginate={this.props.paginate}
                        page_size={this.props.page_size}
                        page={this.props.page}
                        total_pages={this.props.total_pages}
                    />
                );
            default:
                return (
                    <Standard 
                        paginate={this.props.paginate}
                        page_size={this.props.page_size}
                        page={this.props.page}
                        total_pages={this.props.total_pages}
                    />
                );
        }
    }
}

export default Pagination;
