import React, { Component } from 'react'
import './card.scss'

// Assets
import news_1 from '../assets/news-1.jpg'

class CardAlt extends Component {
  render() {
    return (
      <div
        style={this.props.style}
        className={
          this.props.cardClass
            ? `blog-card ${this.props.cardClass}`
            : 'blog-card'
        }
        onClick={() => this.props.onSelect(this.props.post)}
      >
        <div className='img'>
          <img
            src={
              this.props.post &&
              this.props.post.header_image &&
              this.props.post.header_image !== ''
                ? this.props.post.header_image
                : news_1
            }
            alt='post header img'
          />
          {this.props.imageTint ? (
            <div className={this.props.imageTint}></div>
          ) : (
            ''
          )}
          <h4>{this.props.post.title}</h4>
          {this.props.showDate ? (
            <h5>
              {new Date(this.props.post.createdAt).toLocaleDateString('en-uk', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })}
            </h5>
          ) : (
            ''
          )}
        </div>
        {this.props.showSummary ? (
          <div className='content'>
            <p>{this.props.post.summary}</p>
            <h3>{this.props.linkText ? this.props.linkText : ''}</h3>
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }
}

export default CardAlt
