import React, { Component } from 'react'
import './blogPost.scss'
import parse from 'html-react-parser'

class BlogPostAlt extends Component {
  componentDidMount() {
    let urlParams = window.location.pathname
    let params = urlParams.split('/')

    console.log(`BlogPost - ${params[3]}`)
    console.log(params)

    if (Object.keys(this.props.post).length === 0 && params.length >= 4) {
      this.props
        .getPostById(params[3])
        .then((data) => {})
        .catch((data) => {
          console.log(data)
        })
    }
  }

  render() {
    return (
      <div className="post">
        {this.props.post &&
        this.props.post.title &&
        this.props.post.title !== '' ? (
          <div className='blog-post'>
            {this.props.hideHeader ? (
              <div className='blog-post-header'>
                <div className='blog-post-content-container'>
                  <div className='blog-post-content'>
                    {parse(this.props.post.wysiwyg || '')}
                  </div>
                </div>
              </div>
            ) : (
              <div className='blog-post-header'>
                <h1>{this.props.post.title || ''}</h1>
                <div className='blog-author'>
                  {this.props.author_image && this.props.author_image !== '' ? (
                    <img
                      src={
                        this.props.author_image && this.props.author_image != ''
                          ? this.props.author_image
                          : ''
                      }
                      alt='author avatar'
                    />
                  ) : (
                    ''
                  )}
                  <p>
                    by{' '}
                    {this.props.author_name && this.props.author_name != ''
                      ? this.props.author_name
                      : 'author'}{' '}
                    <strong>
                      <h6>
                        {this.props.post.createdAt
                          ? new Date(
                              this.props.post.createdAt
                            ).toLocaleDateString('en-uk', {
                              weekday: 'long',
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric'
                            })
                          : ''}
                      </h6>
                    </strong>
                  </p>
                </div>

                <div className='img'>
                  <img
                    src={
                      this.props.post &&
                      this.props.post.header_image &&
                      this.props.post.header_image !== ''
                        ? this.props.post.header_image
                        : this.props.defaultHeader
                    }
                    alt='header'
                  />
                </div>
                <div className='blog-post-content'>
                  {parse(this.props.post.wysiwyg || '')}
                </div>
              </div>
            )}
          </div>
        ) : this.props.noPostsComponent ? (
          this.props.noPostsComponent
        ) : (
          ''
        )}
      </div>
    )
  }
}

export default BlogPostAlt
