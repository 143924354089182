import React from 'react';

export const spinner1 = (
	<svg 
		xmlns="http://www.w3.org/2000/svg" 
		style={{
			margin: "auto",
			background: "none",
			display: "block",
			shapeRendering: "auto"
		}} 
		width="200px" 
		height="200px" 
		className="dg-spinner-item"
		viewBox="0 0 100 100" 
		preserveAspectRatio="xMidYMid"
	>
		<g transform="rotate(0 50 50)">
		<rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#bb3131">
			<animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite"></animate>
		</rect>
		</g><g transform="rotate(30 50 50)">
		<rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#bb3131">
			<animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
		</rect>
		</g><g transform="rotate(60 50 50)">
		<rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#bb3131">
			<animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
		</rect>
		</g><g transform="rotate(90 50 50)">
		<rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#bb3131">
			<animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
		</rect>
		</g><g transform="rotate(120 50 50)">
		<rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#bb3131">
			<animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite"></animate>
		</rect>
		</g><g transform="rotate(150 50 50)">
		<rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#bb3131">
			<animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
		</rect>
		</g><g transform="rotate(180 50 50)">
		<rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#bb3131">
			<animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite"></animate>
		</rect>
		</g><g transform="rotate(210 50 50)">
		<rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#bb3131">
			<animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
		</rect>
		</g><g transform="rotate(240 50 50)">
		<rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#bb3131">
			<animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
		</rect>
		</g><g transform="rotate(270 50 50)">
		<rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#bb3131">
			<animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
		</rect>
		</g><g transform="rotate(300 50 50)">
		<rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#bb3131">
			<animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite"></animate>
		</rect>
		</g><g transform="rotate(330 50 50)">
		<rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#bb3131">
			<animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
		</rect>
		</g>
	</svg>
);

export const gear = (
	<svg 
		xmlns="http://www.w3.org/2000/svg" 
		style={{
			margin: "auto",
			background: "none",
			display: "block",
			shapeRendering: "auto"
		}} 
		width="200px" 
		height="200px" 
		viewBox="0 0 100 100" 
		className="dg-spinner-item"
		preserveAspectRatio="xMidYMid"
	>
		<g transform="translate(50 50)">
		<g>
		<animateTransform attributeName="transform" type="rotate" values="0;45" keyTimes="0;1" dur="0.5813953488372093s" repeatCount="indefinite"></animateTransform><path d="M29.491524206117255 -5.5 L37.491524206117255 -5.5 L37.491524206117255 5.5 L29.491524206117255 5.5 A30 30 0 0 1 24.742744050198738 16.964569457146712 L24.742744050198738 16.964569457146712 L30.399598299691117 22.621423706639092 L22.621423706639096 30.399598299691114 L16.964569457146716 24.742744050198734 A30 30 0 0 1 5.5 29.491524206117255 L5.5 29.491524206117255 L5.5 37.491524206117255 L-5.499999999999997 37.491524206117255 L-5.499999999999997 29.491524206117255 A30 30 0 0 1 -16.964569457146705 24.742744050198738 L-16.964569457146705 24.742744050198738 L-22.621423706639085 30.399598299691117 L-30.399598299691117 22.621423706639092 L-24.742744050198738 16.964569457146712 A30 30 0 0 1 -29.491524206117255 5.500000000000009 L-29.491524206117255 5.500000000000009 L-37.491524206117255 5.50000000000001 L-37.491524206117255 -5.500000000000001 L-29.491524206117255 -5.500000000000002 A30 30 0 0 1 -24.742744050198738 -16.964569457146705 L-24.742744050198738 -16.964569457146705 L-30.399598299691117 -22.621423706639085 L-22.621423706639092 -30.399598299691117 L-16.964569457146712 -24.742744050198738 A30 30 0 0 1 -5.500000000000011 -29.491524206117255 L-5.500000000000011 -29.491524206117255 L-5.500000000000012 -37.491524206117255 L5.499999999999998 -37.491524206117255 L5.5 -29.491524206117255 A30 30 0 0 1 16.964569457146702 -24.74274405019874 L16.964569457146702 -24.74274405019874 L22.62142370663908 -30.39959829969112 L30.399598299691117 -22.6214237066391 L24.742744050198738 -16.964569457146716 A30 30 0 0 1 29.491524206117255 -5.500000000000013 M0 -20A20 20 0 1 0 0 20 A20 20 0 1 0 0 -20" fill="#e15b64"></path></g></g>
	</svg>
);